<template>
  <div class="filter-content__box-container">
    <div class="filter-content__box-header">
      <div class="filter-content__label">
        {{ filters["date-range"] }}
      </div>
      <div
        class="filter-content__clear"
        :class="{ 'filter-content__clear-disabled': disableClearFilter }"
        @click="clear"
      >
        <autorenew-icon class="filter-content__clear-icon" />
        <span>{{ filters.clear }}</span>
      </div>
    </div>
    <div class="filter-content__description">
      {{ filters["compare-date"] }}
    </div>
    <div class="filter-content__box">
      <div>
        <input-date
          ref="firstDate"
          class="datepicker"
          :options="{ opens: 'left' }"
          :startDate="firstStartDate"
          :endDate="firstEndDate"
          @update="rangeChanged(arguments[0], arguments[1], 'first')"
          @select="selectDate"
        />
        <div class="filter-content__subtitle">
          <span>{{ `${filters["date-range-for"]} ` }}</span>
          <span class="filter-content__subtitle-accent"
            >{{ firstCampaign.name | truncate(25) }}
          </span>
        </div>
      </div>
      <div class="filter-content__comparison">
        {{ filters.compare }}
        <compare-icon />
      </div>
      <div>
        <input-date
          ref="secondDate"
          class="datepicker"
          :options="{ opens: 'left' }"
          :startDate="secondStartDate"
          :endDate="secondEndDate"
          @update="rangeChanged(arguments[0], arguments[1], 'second')"
          @select="selectDate"
        />
        <div class="filter-content__subtitle">
          <span>{{ `${filters["date-range-for"]} ` }}</span>
          <span class="filter-content__subtitle-accent"
            >{{ secondCampaign.name | truncate(25) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDate from '@/components/InputDate.vue';

export default {
  components: {
    InputDate,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    disabledFilter: {
      type: Boolean,
    },
    firstCampaign: {
      type: Object,
    },
    secondCampaign: {
      type: Object,
    },
    firstDate: {
      type: Object,
    },
    secondDate: {
      type: Object,
    },
  },
  data() {
    return {
      firstStartDate: this.firstDate.start,
      firstEndDate: this.firstDate.end,
      secondStartDate: this.secondDate.start,
      secondEndDate: this.secondDate.end,
      rangeDays: null,
    };
  },
  watch: {
    firstDate(newValue) {
      this.firstStartDate = newValue.start;
      this.firstEndDate = newValue.end;
    },
    secondDate(newValue) {
      this.secondStartDate = newValue.start;
      this.secondEndDate = newValue.end;
    },
  },
  computed: {
    filters() {
      return this.$t('chart.filters');
    },
    disableSecondDate() {
      return !this.firstStartDate;
    },
    disableClearFilter() {
      return !this.secondDate.start && !this.secondDate.end;
    },
  },
  methods: {
    rangeChanged(start, end, name) {
      let startDate = start;
      let endDate = end;

      this[`${name}StartDate`] = start;
      this[`${name}EndDate`] = end;


      if (startDate != null) {
        startDate = this.$moment(startDate).format('YYYY-MM-DD');

        endDate = this.$moment(endDate).format('YYYY-MM-DD');
        this.rangeDays = this.rangeSize(start, end);
      }

      const filters = {
        startDate,
        endDate,
      };

      this.$emit(name === 'first' ? 'change-value' : 'filter', filters);
    },
    selectDate() {
      this.secondStartDate = null;
      this.secondEndDate = null;
      this.$refs.secondDate.reset();
    },
    rangeSize(start, end) {
      return this.$moment(end).diff(this.$moment(start), 'days');
    },
    getEndDate(start) {
      return this.$moment(start).add(this.rangeDays, 'days').format('YYYY-MM-DD');
    },
    clear() {
      this.firstStartDate = null;
      this.firstEndDate = null;
      this.secondStartDate = null;
      this.secondEndDate = null;
      this.$refs.firstDate.reset();
      this.$refs.secondDate.reset();

      this.$emit('clear-date');
    },
  },
  mounted() {
    if (this.firstStartDate) {
      this.rangeDays = this.rangeSize(this.firstStartDate, this.firstEndDate);
    }
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_chart-filters.scss";
</style>
