<template>
  <div
    class="filter-content__box-container"
    :class="{ 'filter-content__box-warning': !allowFilter }"
  >
    <div class="filter-content__box-header">
      <div class="filter-content__label">{{ filters[name] }}</div>
      <div
        class="filter-content__clear"
        :class="{ 'filter-content__clear-disabled': disabledFilter }"
        @click="clear"
      >
        <autorenew-icon class="filter-content__clear-icon" />
        <span>{{ filters.clear }}</span>
      </div>
    </div>
    <div class="filter-content__description" v-if="name === 'campaign'">
      {{ filters["compare-campaign"] }}
    </div>
    <div class="filter-content__description" v-else>
      <span>{{ $t("chart.filters.compare-channel") }}</span>
      <span class="filter-content__description-item"
        >{{ ` ${selectedCampaign.name}` }}
      </span>
    </div>
    <div class="filter-content__box">
      <hcc-select
        class="filter-content__item"
        track-by="id"
        option-label="name"
        v-model="first"
        :placeholder="placeholder"
        :options="options"
        :disabled="!allowFilter"
        custom-class="select"
        @select="updateItem"
        :allowEmpty="name === 'channel'"
      />
      <div class="filter-content__comparison">
        {{ filters.compare }}
        <compare-icon />
      </div>
      <hcc-select
        class="filter-content__item"
        track-by="id"
        option-label="name"
        v-model="second"
        :placeholder="placeholder"
        :options="options"
        :disabled="!allowFilter || disableSecond"
        custom-class="select"
        @select="filterItem"
        @remove="removeItem"
        :allowEmpty="name === 'channel'"
      />
    </div>
    <div v-if="!allowFilter" class="filter-content__info">
      <span class="filter-content__info-icon">*</span>
      {{ filters[`no-${name}s`] }}
    </div>
  </div>
</template>

<script>
import HccSelect from '@/components/shared/HccSelect/index.vue';

export default {
  components: {
    HccSelect,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    disabledFilter: {
      type: Boolean,
    },
    firstItem: {
      type: Object,
    },
    secondItem: {
      type: Object,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
    },
    selectedCampaign: {
      type: Object,
    },
  },
  watch: {
    info(newValue) {
      this.first = newValue.id ? newValue : null;
      this.second = null;
    },
  },
  data() {
    return {
      first: null,
      second: null,
    };
  },
  computed: {
    filters() {
      return this.$t('chart.filters');
    },
    allowFilter() {
      return this.options.length > 1;
    },
    disableSecond() {
      return !this.first;
    },
  },
  methods: {
    updateItem(item) {
      this.first = item;
      this.$emit('change-value', item);
    },
    filterItem(item) {
      this.second = item;
      this.$emit('enable-filter', this.capitalizeFirst(this.name));
      this.$emit('filter', item);
    },
    removeItem() {
      this.first = null;
      this.second = null;
      this.$emit('remove-value');
    },
    clear() {
      this.first = (this.name === 'campaign' && this.firstItem.id) ? this.firstItem : null;
      this.second = null;
      this.$emit('clear-filter');
    },
  },
  mounted() {
    this.first = this.firstItem && this.firstItem.id ? this.firstItem : null;
    this.second = this.secondItem && this.secondItem.id ? this.secondItem : null;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_chart-filters.scss";
</style>
