var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"main-content"},[_c('div',{staticClass:"grid-x grid-margin-x"},[_c('div',{staticClass:"cell small-12 medium-9 large-3"},[_c('ul',{staticClass:"vertical tabs"},_vm._l((_vm.tabs),function(tab){return _c('router-link',{key:tab.component,attrs:{"custom":"","to":{ name: tab.component }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[
                'tabs-title',
                { 'is-active': isExactActive } ],on:{"click":navigate}},[_c('span',{attrs:{"aria-selected":isExactActive},on:{"click":function($event){return _vm.setCurrentTab(tab.id)}}},[_c(tab.icon,{tag:"component"}),_vm._v(" "+_vm._s(_vm.$t(tab.title))+" ")],1)])]}}],null,true)})}),1)]),_c('div',{staticClass:"cell small-12 large-9"},[_c('div',{staticClass:"tabs-content"},[_c('div',{staticClass:"grid-x align-justify"},[_c('div',{staticClass:"cell small-12 medium-shrink"},[_c('h1',{staticClass:"heading heading-2"},[_vm._v(_vm._s(_vm.$t(_vm.currentTabTitle)))])]),_c('div',{staticClass:"cell small-12 medium-shrink"},[_c('button-filter',{attrs:{"display":_vm.displayFilters,"initialStartDate":_vm.$moment().format(),"initialEndDate":_vm.$moment().format()},on:{"change":_vm.onFiltersChanged}})],1)]),_c('filters',{attrs:{"channel":_vm.selectedChannel,"agents":_vm.selectedAgents,"date":_vm.date}}),_c('div',{staticClass:"tabs-panel is-active"},[_c('router-view',{attrs:{"filters":_vm.globalFilters.filters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var Component = ref.Component;
              var route = ref.route;
return [_c('transition',{attrs:{"name":route.meta.transition || 'fade',"mode":"out-in"}},[_c('keep-alive',[_c('suspense',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(Component,{key:route.meta.usePathKey ? route.path : undefined,tag:"component"})]},proxy:true},{key:"fallback",fn:function(){return [_vm._v("Loading...")]},proxy:true}],null,true)})],1)],1)]}}])})],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }