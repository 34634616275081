<template>
  <div class="chart">
    <div class="chart-title">
      <h1 v-text="$t(name)" class="text-subtitle"></h1>
      <div class="filters">
        <download-options
          @export-chart="exportChartAs"
          :disabled="isLoading || !hasData"
        />
        <hcc-select
          v-if="filterSeries"
          v-model="filterSelection"
          track-by="name"
          optionLabel="name"
          :options="filterOptions"
          :placeholder="filterPlaceholder"
          :multiple="true"
          :allowEmpty="true"
          :close-on-select="false"
          :limit-text="filterLimit"
          :limit="1"
          customClass="select"
          :disabled="isLoading"
        />
        <input-date
          ref="inputDate"
          :key="key"
          :startDate="currentStartDate"
          :endDate="currentEndDate"
          :options="{ opens: 'left' }"
          :disabled="isLoading"
          class="date"
          @update="rangeChanged"
        />
        <chart-filters
          v-if="showChartFilters"
          ref="chartFilters"
          :filters="filters"
          :allowChannelFilter="allowChannelFilter"
          @change-value="changeFilterValue"
          @filter="updateFilter"
          @clear-filter="clearFilter"
          @clear-date="clearDateFilter"
        />
      </div>
    </div>
    <div class="chart-content" v-resize="onResize">
      <div class="chart-pagination">
        <span
          class="nav-previous"
          v-if="paginationEnabled"
          v-bind:class="{ disabled: !previousEnabled }"
          v-on:click="previousPage"
        >
          <chevron-left-icon />
        </span>
        <div
          class="chart-wrapper"
          v-bind:class="{ paginated: paginationEnabled }"
        >
          <div class="empty-filter" v-if="showDoubleHeatmap">
            {{ seriesName }}
          </div>
          <chart-component
            ref="chartComponent"
            :null-to-zeroes="nullToZeroes"
            :translatable="translatable"
            :time-format="timeFormat"
            :chart-id="chartId"
            :date-format="dateFormat"
            :date-y-format="dateYFormat"
            :labels="chartLabels"
            :series="chartSeries"
            :type="type"
            :type-loader="typeLoader"
            :isEmpty="isEmpty"
            :isEmptyLine="isEmptyLine"
            :isEmptyStacked="isEmptyStacked"
            :secondHeatmapSeries="secondHeatmapSeries"
            :hasData="hasData"
            :doubleChart="doubleChart"
            :stackName="stackName"
          />
          <div v-if="showDoubleHeatmap">
            <div class="empty-filter" v-if="showEmptyResulToHeatMap">
              {{ secondSerieName }}
            </div>
            <chart-component
               v-if="showEmptyResulToHeatMap"
              ref="chartComponent"
              :null-to-zeroes="nullToZeroes"
              :translatable="translatable"
              :time-format="timeFormat"
              :chart-id="chartId"
              :date-format="dateFormat"
              :date-y-format="dateYFormat"
              :labels="chartLabels"
              :series="secondHeatmapSeries.series || []"
              :type="type"
              :type-loader="typeLoader"
              :isEmpty="isEmpty"
              :isEmptyLine="isEmptyLine"
              :isEmptyStacked="isEmptyStacked"
              :hasData="hasData"
              :doubleChart="doubleChart"
              :stackName="stackName"
            />
            <div
                class="empty-filter"
                v-else
              >
                {{ "* " + $t("chart.filters['empty-filter']", { filter: stackName }) }}
              </div>
          </div>
          <span
            class="nav-next"
            v-if="paginationEnabled"
            :class="{ disabled: !nextEnabled }"
            @click="nextPage"
          >
            <chevron-right-icon />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';
import { mapState } from 'vuex';
import EventBus from '@/eventBus';
import { LOCALE_LAGUAGE_CHANGE } from '@/eventTypes';
import ChartFilters from '@/components/ChartFilters.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';

export default {
  components: {
    ChartComponent: () => import('@/components/ChartComponent.vue'),
    InputDate: () => import('@/components/InputDate.vue'),
    DownloadOptions: () => import('@/components/DownloadOptions.vue'),
    HccSelect,
    ChartFilters,
  },
  directives: {
    resize,
  },
  props: {
    nullToZeroes: {
      type: Boolean,
      default: false,
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    timeFormat: {
      type: Boolean,
      default: false,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 7,
    },
    filterSeries: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    chartId: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
    },
    type: {
      required: true,
      type: String,
      validator(value) {
        return ['bar', 'column', 'stackedColumn', 'line', 'pie', 'heatmap'].indexOf(value) !== -1;
      },
    },
    doubleChart: {
      type: Boolean,
      default: false,
    },
    typeLoader: {
      required: false,
      type: String,
      default: 'chart',
    },
    stackName: {
      type: String,
      default: null,
    },
    filters: {
      type: Object,
    },
    secondHeatmapSeries: {
      type: Object,
    },
    showChartFilters: {
      type: Boolean,
      default: true,
    },
    allowChannelFilter: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    EventBus.$on(LOCALE_LAGUAGE_CHANGE, () => {
      this.key += 1;
    });
  },
  data() {
    return {
      key: 0,
      currentPage: 0,
      currentStartDate: null,
      currentEndDate: null,
      dateFormat: null,
      dateYFormat: null,
      labels: [],
      series: [],
      filterSelection: [],
    };
  },
  computed: {
    ...mapState({
      loader: state => state.dashboard.loader || {},
    }),
    paginationEnabled() {
      return this.paginate && this.totalPages > 1;
    },
    previousEnabled() {
      return this.currentPage > 0;
    },
    nextEnabled() {
      return this.currentPage < this.totalPages - 1;
    },
    totalElements() {
      return this.labels.length;
    },
    totalPages() {
      return Math.ceil(this.totalElements / this.perPage);
    },
    startElement() {
      return this.currentPage * this.perPage;
    },
    endElement() {
      return this.startElement + this.perPage;
    },
    chartLabels() {
      if (this.paginate) {
        return this.paginatedLabels();
      }

      return this.labels;
    },
    chartSeries() {
      if (this.paginate) {
        return this.paginatedSeries();
      }

      if (this.filterSeries) {
        return this.filteredSeries;
      }

      return this.series;
    },
    filteredSeries() {
      if (this.filterSelection.length > 0) {
        const selection = this.filterSelection.map(item => item.name);
        return this.series.filter(el => selection.includes(el.name));
      }

      return this.series;
    },
    filterOptions() {
      const options = this.series.map((el, i) => ({ name: el.name, id: i })).sort();
      return options;
    },
    filterPlaceholder() {
      return this.placeholder !== '' ? this.placeholder : this.$t('multiselect.placeholder');
    },
    isLoading() {
      return this.loader[this.chartId];
    },
    isEmpty() {
      return this.chartSeries?.length === 0;
    },
    isEmptyLine() {
      return (this.type === 'line' && (this.chartSeries[0] && this.chartSeries[0]?.data.length === 0))
      || (this.type === 'line' && this.chartSeries.every(serie => serie.data.every(data => data === 0)));
    },
    isEmptyStacked() {
      return this.chartSeries ? (this.type === 'stackedColumn' && (this.chartSeries[0] && this.chartSeries[0]?.data.length === 0))
        || (this.type === 'stackedColumn' && this.chartSeries.length === 0) : true;
    },
    hasData() {
      return !this.isEmpty && !this.isEmptyLine && !this.isEmptyStacked;
    },
    showDoubleHeatmap() {
      return this.type === 'heatmap' && this.doubleChart;
    },
    seriesName() {
      return this.series[0]?.stack || '';
    },
    secondSerieName() {
      return this.secondHeatmapSeries?.name || '';
    },
    showEmptyResulToHeatMap() {
      return this.showDoubleHeatmap && this.secondHeatmapSeries.series.length > 0;
    },
  },
  watch: {
    chartData(newValue) {
      if (newValue) {
        this.dateFormat = newValue.dateFormat ?? null;
        this.dateYFormat = newValue.dateYFormat ?? null;
        this.labels = newValue.categories ?? [];
        this.series = newValue.series ?? [];
      }
    },
  },
  methods: {
    previousPage() {
      if (this.previousEnabled) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.nextEnabled) {
        this.currentPage = this.currentPage + 1;
      }
    },
    paginatedLabels() {
      return this.labels.slice(this.startElement, this.endElement);
    },
    paginatedSeries() {
      return this.series.map(el => ({
        ...el,
        data: el.data.slice(this.startElement, this.endElement),
      }));
    },
    rangeChanged(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
      this.currentPage = 0;
      this.filterSelection = [];
      this.$refs.chartFilters.clearData();
      this.$emit('change', this.chartId, startDate, endDate);
    },
    resetFilters() {
      this.currentPage = 0;
      this.filterSelection = [];

      if (this.$refs.inputDate) {
        this.$refs.inputDate.reset();
      }
    },
    onResize() {
      if (this.$refs.chartComponent) {
        this.$refs.chartComponent.triggerResize();
      }
    },
    filterLimit() {
      return `${this.filterSelection.length - 1} ${this.$t('multiselect.limit-suffix')}`;
    },
    exportChartAs(mime) {
      this.$refs.chartComponent.exportAs(this.chartId, mime);
    },
    changeFilterValue(filters) {
      this.$emit('change-filter', filters);
    },
    updateFilter(filters) {
      this.$emit('update-filter', filters);
    },
    clearFilter(filter) {
      this.$emit('clear-filter', filter);
    },
    clearDateFilter(filter) {
      this.$emit('clear-date', filter);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_data-chart.scss";
</style>
