<template>
  <div class="channels__tabs-container">
    <div
      v-for="tab in getTabs()"
      :key="tab.key"
      @mouseover="tab.hover = true"
      @mouseleave="tab.hover = false"
    >
      <hcc-dropdown
        v-if="tab.channels.length > 1"
        :customClass="[
          'channels__options',
          `channels__options-${tab.key}`,
          'custom-position',
        ]"
      >
        <template #button>
          <channel-info :tab="tab" :selectedChannel="selectedChannel" />
        </template>
        <hcc-dropdown-item
          v-for="channel in tab.channels"
          :key="channel.key"
          @click="selectChannel(tab, channel)"
        >
          <span>{{ channel.name }}</span>
        </hcc-dropdown-item>
      </hcc-dropdown>
      <channel-info
        v-else
        :tab="tab"
        :selectedChannel="selectedChannel"
        @change-tab="selectChannel(tab, tab.channels[0])"
        @create-channel="createChannel(tab)"
      />
    </div>
  </div>
</template>

<script>
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import ChannelInfo from './ChannelInfo.vue';

export default {
  components: {
    HccDropdown,
    HccDropdownItem,
    ChannelInfo,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    editedTab: {
      type: Object,
    },
    selectedChannel: {
      type: Object,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: 'WhatsApp',
          key: 'whatsapp',
          hover: true,
          selected: true,
          channels: this.filterChannelsByType('whatsapp'),
        },
        {
          name: 'Telegram',
          key: 'telegram',
          hover: false,
          selected: false,
          channels: this.filterChannelsByType('telegram'),
        },
        {
          name: 'Messenger',
          key: 'messenger',
          hover: false,
          selected: false,
          channels: this.filterChannelsByType('messenger'),
        },
        {
          name: 'SMS',
          key: 'sms',
          hover: false,
          selected: false,
          channels: this.filterChannelsByType('sms'),
        },
        {
          name: 'Webchat',
          key: 'webchat',
          hover: false,
          selected: false,
          channels: this.filterChannelsByType('webchat'),
        },
        {
          name: 'Instagram',
          key: 'instagram',
          hover: false,
          selected: false,
          channels: this.filterChannelsByType('instagram'),
        },
      ],
    };
  },
  methods: {
    filterChannelsByType(type) {
      return this.channels.filter(channel => channel.channelType.key === type);
    },
    getTabs() {
      const { tabs } = this;

      if (this.editedTab) {
        const actualTab = this.tabs.findIndex(tab => tab.selected);

        const key = this.editedTab.channelType
          ? this.editedTab.channelType.key : this.editedTab.key;

        if (this.tabs[actualTab].key !== key) {
          this.tabs[actualTab].selected = false;
          this.tabs[actualTab].hover = false;

          const i = this.tabs.findIndex(tab => tab.key === key);
          this.tabs[i].selected = true;
          this.tabs[i].hover = true;
        }
      }

      return tabs;
    },
    changeTab(tab) {
      const selected = this.tabs.findIndex(channel => channel.selected);

      this.tabs[selected].selected = false;
      this.tabs[selected].hover = false;

      this.tabs.find(channel => channel.key === tab.key).selected = true;

      this.$emit('change-tab', tab);
    },
    selectChannel(tab, channel) {
      this.changeTab(tab);
      this.$emit('change-channel', channel);
    },
    createChannel(tab) {
      this.$emit('create-channel', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";

::v-deep .custom-position {
  &.bottom-right {
    top: -1.25rem;
  }
}
</style>
