<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'clientsDataCards'"
        :theme="dataCards.all.theme"
        :name="dataCards.all.name"
        :value="dataCards.all.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'clientsDataCards'"
        :theme="dataCards.new.theme"
        :name="dataCards.new.name"
        :value="dataCards.new.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'clientsDataCards'"
        :theme="dataCards.rebound.theme"
        :name="dataCards.rebound.name"
        :value="dataCards.rebound.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'clientsDataCards'"
        :theme="dataCards.bounce.theme"
        :name="dataCards.bounce.name"
        :suffix="'%'"
        :value="dataCards.bounce.value | numFormat('0.00')"
      >
        <message-icon />
      </data-card>
    </div>
  </div>
</template>

<script>
import executeQuery from '@/utils/gql-api';
import ClientsDataCards from '@/graphql/queries/clients/dataCards.gql';
import DataCard from '@/components/DataCard.vue';

export default {
  components: {
    DataCard,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      dataCards: {
        all: {
          theme: 'blue',
          name: 'chart.card.clients-all',
          value: 0,
        },
        new: {
          theme: 'green',
          name: 'chart.card.clients-new',
          value: 0,
        },
        rebound: {
          theme: 'purple',
          name: 'chart.card.clients-rebound',
          value: 0,
        },
        bounce: {
          theme: 'red',
          name: 'chart.card.clients-bounce',
          value: 0,
        },
      },
    };
  },
  watch: {
    filters(newValue) {
      if (newValue !== null) {
        this.loadCards();
      }
    },
  },
  methods: {
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    async loadCards() {
      const data = await this.fetchData('clientsDataCards', ClientsDataCards, this.filters);

      this.dataCards.all.value = data.all;
      this.dataCards.new.value = data.new;
      this.dataCards.rebound.value = data.rebound;
      this.dataCards.bounce.value = data.all > 0 ? (data.rebound / data.all * 100) : 0;
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.loadCards();
    }
  },
};
</script>
