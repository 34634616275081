<template>
  <div class="channels__info">
    <div class="channels__group">
      <span>{{ messengerSettings["fan-page-id"] }}</span>
      <span class="channels__description">{{ fanPageID }}</span>
    </div>
    <div class="channels__group">
      <span>{{ $t('channels-settings.instagram.page-id') }}</span>
      <span class="channels__description">{{ instagramId }}</span>
    </div>
    <div class="channels__group">
      <span>{{ messengerSettings["access-token"] }}</span>
      <span class="channels__description">{{ accessToken }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messengerSettings() {
      return this.$t('channels-settings.messenger');
    },
    fanPageID() {
      return this.channel.extras ? this.channel.extras.fanPageId : '';
    },
    instagramId() {
      return this.channel.extras ? this.channel.extras.instagramId : '';
    },
    accessToken() {
      return this.channel.extras && this.channel.extras.accessToken ? this.truncateWithDots(this.channel.extras.accessToken, 40) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
