<template>
  <span class="channels__tab" :class="getClass()" @click="changeTab">
    <img
      v-if="hasChannels"
      :src="getIcon(tab.key)"
      :alt="tab.name"
      class="channels__icon"
    />

    <component v-else :is="tab.key" class="channels__icon-empty" />
    <div>
      <div>
        <span class="channels__key">{{ tab.name }}</span>
        <div
          v-if="tab.selected && tab.channels.length > 0 && this.selectedChannel"
          class="channels__name"
        >
          {{ selectedChannel.name }}
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import messenger from '@/assets/images/channels/messenger.svg';
import sms from '@/assets/images/channels/sms.svg';
import telegram from '@/assets/images/channels/telegram.svg';
import webchat from '@/assets/images/channels/webchat.svg';
import whatsapp from '@/assets/images/channels/whatsapp.svg';
import instagram from '@/assets/images/channels/instagram.svg';

export default {
  props: {
    tab: {
      type: Object,
      required: true,
    },
    selectedChannel: {
      type: Object,
    },
  },
  computed: {
    hasChannels() {
      return this.tab.channels.length > 0;
    },
  },
  methods: {
    getIcon(channel) {
      switch (channel) {
        case 'messenger':
          return messenger;
        case 'sms':
          return sms;
        case 'telegram':
          return telegram;
        case 'webchat':
          return webchat;
        case 'whatsapp':
          return whatsapp;
        case 'instagram':
          return instagram;
        default:
          return '';
      }
    },
    getClass() {
      let newClass;

      if (this.hasChannels) {
        newClass = [
          this.tab.key,
          this.tab.hover && `channels__tab-open ${this.tab.key}-selected`,
          this.tab.selected && `channels__tab-open channels__tab-selected ${this.tab.key}-selected`,
        ];
      } else {
        newClass = [
          'channels__tab-disabled',
          (this.tab.selected || this.tab.hover) && 'channels__tab-open',
        ];
      }

      return newClass;
    },
    changeTab() {
      this.$emit('change-tab');

      if (this.tab.channels.length === 0) {
        this.$emit('create-channel');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
