<template>
  <div>
    <overlay-loader :loading="isLoading" />
    <bot-graph :steps="chartOptions" v-if="chartOptions.length > 0"/>
    <div class="chart-component" v-else>
      <div class="empty-chart">
        <div class="empty-chart__container">
          <img
            :src="require('@/assets/images/line-chart.svg')"
            class="empty-chart__icon"
          />
        </div>
        <span class="empty-chart__text">{{ $t('shared-components.table.empty') }}</span>
      </div>
    </div>
    <hcc-select
      customClass="select"
      label="Selecciona los steps a remover"
      optionLabel=""
      selectedText="Steps seleccionados"
      :preserve-search="true"
      :allow-empty="true"
      v-model="stepsSelected"
      :options="stepsOptions"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      @select="selected"
      :taggable="false"
      @remove="remove"
    />
    <div class="grid-x grid-margin-x">
      <span class="step-label"
        v-for="step in stepsSelected"
        :key="step">{{ step }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import conversationSteps from '@/graphql/queries/conversations/BotStepConversastions.gql';
import executeQuery from '@/utils/gql-api';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  props: {
    filters: Object,
  },
  components: {
    OverlayLoader,
    BotGraph: () => import('@/components/BotGraph.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
  },
  data() {
    return {
      isLoading: false,
      campaignId: null,
      chartOptions: [],
      removedSteps: [],
      stepsOptions: [],
      stepsSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
    }),
    queryConversations() {
      if (!this.filters) {
        return {};
      }

      const start = moment(this.filters.startDate);
      const end = moment(this.filters.endDate).endOf('day');
      return {
        end,
        start,
        campaign: this.campaignId,
      };
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        if (!newValue?.campaignId) {
          this.$toasted.global.error(this.$t('app.ivrConversations'));
        } else if (newValue && this.validRequest(newValue)) {
          this.campaignId = newValue.campaignId;
          this.getConversationSteps();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.filters && this.filters.campaignId !== null) {
      this.campaignId = this.filters.campaignId;
      this.getConversationSteps();
    } else if (this.filters) {
      this.$toasted.global.error(this.$t('app.ivrConversations'));
    }
  },
  methods: {
    async getConversationSteps() {
      this.$store.commit('dashboard/enableLoader', 'conversationIvr');
      this.isLoading = true;
      const { result: steps } = await executeQuery(
        'conversationSteps',
        conversationSteps,
        this.queryConversations,
      );

      this.mapStepValues(steps);
      this.isLoading = false;
    },
    mapStepValues(steps) {
      const charsData = steps.map(step => [step[0], step[1], parseInt(step[2], 0)]);
      const data = steps.flatMap(item => [item[0], item[1]]);
      this.stepsOptions = Array.from(new Set(data));
      this.stepsSelected = this.stepsOptions;
      this.chartOptions = charsData;
    },
    validRequest({ endDate, startDate, campaignId }) {
      return startDate && endDate && campaignId;
    },
    selected(step) {
      const options = this.removedSteps.filter(item => item.includes(step));
      const removed = this.removedSteps.filter(item => !item.includes(step));
      this.removedSteps = removed;
      this.chartOptions = this.chartOptions.concat(options);
    },
    remove(step) {
      const options = this.chartOptions.filter(item => !item.includes(step));
      const toRemove = this.chartOptions.filter(item => item.includes(step));
      this.removedSteps = this.removedSteps.concat(toRemove);
      this.chartOptions = options;
    },
  },
};

</script>
<style scoped lang="scss">
  .empty-rows {
    background-color: white;
  }

  .download {
    margin-bottom: 10px;
  }

  .empty-chart {
    background-color: white;
  }

  @import '~styles/components/_data-chart.scss';
</style>
