<template>
  <div class="filter">
    <div class="filter-wrapper">
      <hcc-button
        variant="outline"
        color="primary"
        v-click-outside="vcoConfig"
        @click="toggle"
        class="filter__button"
      >
        <filter-variant-icon />
        {{ filtersTitles.title }}
      </hcc-button>
      <div ref="filterBox" v-if="isVisible" :class="filterContentClass">
        <div class="filter-content__container">
          <div class="filter-content__title">{{ filtersTitles.advanced }}</div>
          <chart-filter-box
            name="campaign"
            :disabledFilter="disabledCampaign"
            :placeholder="filtersTitles['select-campaign']"
            :firstItem="firstCampaign"
            :secondItem="secondCampaign"
            :options="campaigns"
            @enable-filter="enableFilter"
            @change-value="updateCampaign"
            @filter="filterCampaign"
            @clear-filter="clearFilterCampaign"
          />
          <transition name="fade" mode="out-in">
            <chart-filter-box
              style="animation-duration: 0.5s"
              v-if="showChannelFilter"
              name="channel"
              :disabledFilter="disabledChannel"
              :placeholder="filtersTitles['select-channel']"
              :firstItem="firstChannel"
              :secondItem="secondChannel"
              :options="channels"
              :selectedCampaign="firstCampaign"
              @enable-filter="enableFilter"
              @change-value="updateChannel"
              @filter="filterChannel"
              @clear-filter="clearFilterChannel(false)"
              @remove-value="clearFilterChannel(true)"
            />
            <chart-filter-date-box
              style="animation-duration: 0.5s"
              v-else-if="dateFilter"
              name="date"
              :firstCampaign="firstCampaign"
              :secondCampaign="secondCampaign"
              :firstDate="firstDate"
              :secondDate="secondDate"
              @change-value="updateDate"
              @filter="filterDate"
              @clear-date="clearFilterDate"
            />
          </transition>
          <hcc-button
            size="xs"
            color="muted"
            variant="outline"
            @click="onClose"
            class="close-button"
            >{{ $t("filter.close") }}</hcc-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { mapGetters } from 'vuex';
import HccButton from '@/components/shared/HccButton/index.vue';
import ChartFilterBox from './ChartFilterBox.vue';
import ChartFilterDateBox from './ChartFilterDateBox.vue';

export default {
  components: {
    HccButton,
    ChartFilterBox,
    ChartFilterDateBox,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    filters: {
      type: Object,
    },
    allowChannelFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClose,
        middleware: this.middleware,
        events: ['click'],
      },
      isVisible: false,
      firstCampaign: this.campaign?.id ? this.campaign : {},
      secondCampaign: null,
      firstChannel: this.channel?.name ? this.channel : {},
      secondChannel: null,
      firstStartDate: null,
      secondStartDate: null,
      firstEndDate: null,
      secondEndDate: null,
      disabledCampaign: true,
      disabledChannel: true,
      channelFilter: true,
      dateFilter: false,
    };
  },
  watch: {
    campaign(newValue) {
      if (newValue.id) {
        this.firstCampaign = newValue;
        this.clearData();
      }
    },
    filters(newValue) {
      if (newValue) {
        this.clearData();
      }
    },
  },
  computed: {
    ...mapGetters({
      company: 'shared/getCurrentCompany',
      campaign: 'setting/getCurrentCampaign',
      channel: 'setting/getCurrentChannel',
    }),
    filtersTitles() {
      return this.$t('chart.filters');
    },
    campaigns() {
      const campaigns = this.company
        ? this.company.campaigns.map(({ id, name }) => ({ id, name })) : [];

      return campaigns;
    },
    channels() {
      if (this.company) {
        const campaign = this.company.campaigns.find(({ id }) => id === this.currentCampaignId);

        if (campaign && campaign.channels) {
          return campaign.channels.map(({ id, name }) => ({ id, name }));
        }
      }
      return [];
    },
    currentCampaignId() {
      return this.firstCampaign ? this.firstCampaign.id : null;
    },
    currentChannelId() {
      return this.firstChannel ? this.firstChannel.id : null;
    },
    showChannelFilter() {
      return this.channelFilter && this.channels.length > 1 && this.allowChannelFilter;
    },
    filterContentClass() {
      return ['filter-content', {
        filter: this.showChannelFilter || this.dateFilter,
        'all-filters': this.showChannelFilter && this.dateFilter,
      }];
    },
    firstDate() {
      return {
        start: this.firstStartDate,
        end: this.firstEndDate,
      };
    },
    secondDate() {
      return {
        start: this.secondStartDate,
        end: this.secondEndDate,
      };
    },
  },
  methods: {
    middleware(event) {
      return !!this.$refs.filterBox && !this.$refs.filterBox.contains(event.target);
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onClose() {
      this.isVisible = false;
    },
    enableFilter(filter) {
      this[`disabled${filter}`] = false;
    },
    updateCampaign(campaign) {
      this.firstCampaign = campaign;
      this.$emit('change-value', { campaignId: campaign.id, name: campaign.name });
    },
    updateChannel(channel) {
      this.firstChannel = channel;
      this.$emit('change-value', { campaignId: this.firstCampaign.id, channelId: channel.id, name: channel.name });
    },
    updateDate(date) {
      this.firstStartDate = this.$moment(date.startDate).toDate();
      this.firstEndDate = this.$moment(date.endDate).toDate();

      const filters = {
        campaignId: this.firstCampaign.id,
        startDate: date.startDate,
        endDate: date.endDate,
        name: this.firstCampaign.name,
      };

      this.$emit('change-value', filters);
    },
    filterCampaign(campaign) {
      this.secondCampaign = campaign;
      this.clearChannelData();
      this.clearDateData();
      this.channelFilter = false;
      this.dateFilter = true;

      this.$emit('filter', { campaignId: campaign.id, name: campaign.name });
    },
    filterChannel(channel) {
      this.secondChannel = channel;
      this.$emit('filter',
        {
          campaignId: this.firstCampaign.id,
          channelId: channel.id,
          name: channel.name,
        });
    },
    filterDate(date) {
      this.secondStartDate = this.$moment(date.startDate).toDate();
      this.secondEndDate = this.$moment(date.endDate).toDate();

      const filters = {
        campaignId: this.secondCampaign.id,
        startDate: date.startDate,
        endDate: date.endDate,
        name: this.secondCampaign.name,
      };

      this.$emit('filter', filters);
    },
    clearFilterCampaign() {
      this.clearCampaignData();
      this.disabledCampaign = true;
      this.channelFilter = true;
      this.dateFilter = false;


      this.$emit('clear-filter',
        {
          campaignId: this.firstCampaign?.id,
        });
    },
    clearFilterChannel(removeChannel) {
      this.clearChannelData();
      this.disabledChannel = true;

      const filters = {
        campaignId: this.firstCampaign.id,
        name: this.remove ? this.firstCampaign?.name : this.firstChannel?.name,
      };

      if (!removeChannel) {
        filters.channelId = this.firstChannel?.id;
      }

      this.$emit('clear-filter', filters);
    },
    clearFilterDate() {
      this.clearDateData();

      this.$emit('clear-date', {
        firstCampaign: this.firstCampaign,
        secondCampaign: this.secondCampaign,
      });
    },
    clearCampaignData() {
      this.firstCampaign = (this.campaign && this.campaign.id) ? this.campaign : null;
      this.secondCampaign = null;
    },
    clearChannelData() {
      this.firstChannel = this.channel?.name ? this.channel : null;
      this.secondChannel = null;
    },
    clearDateData() {
      this.firstStartDate = null;
      this.secondStartDate = null;
      this.firstEndDate = null;
      this.secondEndDate = null;
    },
    clearData() {
      this.clearCampaignData();
      this.clearChannelData();
      this.clearDateData();

      this.disabledCampaign = true;
      this.channelFilter = true;
      this.dateFilter = false;
    },
  },
  mounted() {
    this.clearData();
  },

};
</script>

<style scoped lang="scss">
@import "~styles/components/_chart-filters.scss";
.filter-content {
  height: fit-content !important;
}
</style>
