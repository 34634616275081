<template>
  <div class="channels__info">
    <div class="channels__group">
      <hcc-button
        variant="outline"
        color="primary"
        v-if="!token"
        @click="logInWithFacebook"
        >{{ loginWFacebook }}
      </hcc-button>
    </div>
    <div class="channels__group">
      <hcc-input
        :label="accessToken"
        v-model="token"
        customClass="channels__input"
        :disabled="pending"
        @input="changeData($event, 'accessToken')"
      />
    </div>
    <div class="channels__group">
      <hcc-select
        :label="fanPageID"
        ref="categoryList"
        custom-class="channels__input"
        :options="facebookPages"
        :option-label="'name'"
        :track-by="'id'"
        :requiredInput="true"
        v-model="fanPage"
        :disabled="pending"
        @input="changeData($event ? $event.id : null, 'fanPageId')"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    HccInput,
    HccSelect,
    HccButton,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fanPage: null,
      token: '',
      extras: {},
      facebookPages: [],
    };
  },
  inject: ['isPending'],
  computed: {
    fanPageID() {
      return this.$t('channels-settings.messenger.fan-page-id');
    },
    accessToken() {
      return this.$t('channels-settings.messenger.access-token');
    },
    findID() {
      return this.$t('channels-settings.messenger.find-id');
    },
    loginWFacebook() {
      return this.$t('channels-settings.messenger.loginFb');
    },
    pending() {
      return this.isPending();
    },
  },
  methods: {
    changeData(value, key) {
      this.extras[key] = value;
      this.messengerExtras();
    },
    messengerExtras() {
      this.$emit('update-extras', this.extras);
    },
    logInWithFacebook() {
      const vm = this;
      window.FB.login(({ authResponse }) => {
        if (authResponse) {
          vm.changeData(authResponse.accessToken, 'accessToken');
          vm.token = authResponse.accessToken;
        }
      }, { scope: 'public_profile,pages_messaging,pages_manage_metadata,pages_show_list,pages_read_engagement' });
    },
    loadFacebookSDK(doc, tag, id) {
      const fjs = doc.getElementsByTagName(tag)[0];
      if (doc.getElementById(id)) {
        return;
      }

      const js = doc.createElement(tag);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    eventsFb() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: process.env.VUE_APP_FB_APP_ID,
          cookie: true,
          xfbml: true,
          version: process.env.VUE_APP_FB_APP_VERSION,
        });

        window.FB.AppEvents.logPageView();
      };
    },
    async listAllPages() {
      try {
        const version = process.env.VUE_APP_FB_APP_VERSION;

        const { data: pages } = await axios.get(
          `https://graph.facebook.com/${version}/me/accounts`, {
            params: {
              access_token: this.token,
            },
          },
        );

        this.facebookPages = pages.data.map(({ id, name }) => ({ id, name: `${id} - ${name}` }));
        if (this.facebookPages.length === 1) {
          const [fanPage] = this.facebookPages;
          this.fanPage = fanPage;
          this.changeData(fanPage ? fanPage.id : null, 'fanPageId');
        }
      } catch (err) {
        errorHandler.logErrors(err);
        this.facebookPages = [];
      }
    },
  },
  created() {
    this.eventsFb();
    this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
    const fanPage = this.channel.extras ? this.channel.extras.fanPageId : null;
    this.fanPage = fanPage ? { id: fanPage, name: fanPage } : null;
    this.token = this.channel.extras ? this.channel.extras.accessToken : '';
    this.extras = {
      fanPageId: this.fanPage.id,
    };
  },
  watch: {
    token(newValue) {
      if (this.token && newValue) {
        this.listAllPages();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/channelSettings/_campaign-channels-settings.scss";
</style>
