<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-4">
      <data-card
        v-bind:card-id="'messagesDataCards'"
        v-bind:theme="dataCards.total.theme"
        v-bind:name="dataCards.total.name"
        v-bind:value="dataCards.total.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'messagesDataCards'"
        :theme="dataCards.inbound.theme"
        :name="dataCards.inbound.name"
        :value="dataCards.inbound.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'messagesDataCards'"
        :theme="dataCards.outbound.theme"
        :name="dataCards.outbound.name"
        :value="dataCards.outbound.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'messagesDataCards'"
        :theme="dataCards.templateMsg.theme"
        :name="dataCards.templateMsg.name"
        :value="dataCards.templateMsg.value | numFormat"
        :show-icon-info="dataCards.templateMsg.info"
        @info-clicked="openReports"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'messagesDataCards'"
        :theme="dataCards.templateMsgRead.theme"
        :name="dataCards.templateMsgRead.name"
        :value="dataCards.templateMsgRead.value | numFormat"
      >
        <message-icon />
      </data-card>
    </div>
    <div class="cell small-12 medium-6 large-4">
      <data-card
        :card-id="'messagesDataCards'"
        :theme="dataCards.templateRateMsg.theme"
        :name="dataCards.templateRateMsg.name"
        :prefix="'$'"
        :suffix="' USD'"
        :show-as-warning="templatesCostExceeded"
        :value="dataCards.templateRateMsg.value | numFormat('0.00')"
      >
        <message-icon />
      </data-card>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import executeQuery from '@/utils/gql-api';
import MessagesDataCards from '@/graphql/queries/messages/dataCards.gql';
import DataCard from '@/components/DataCard.vue';

export default {
  components: {
    DataCard,
  },
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      dataCards: {
        total: {
          theme: 'blue',
          name: 'chart.card.msg-total',
          value: 0,
        },
        inbound: {
          theme: 'purple',
          name: 'chart.card.msg-inbound',
          value: 0,
        },
        outbound: {
          theme: 'red',
          name: 'chart.card.msg-outbound',
          value: 0,
        },
        templateMsg: {
          theme: 'orange',
          name: 'chart.card.msg-template',
          value: 0,
          info: 'chart.card.info-report',
        },
        templateMsgRead: {
          theme: 'red',
          name: 'chart.card.read-msg-template',
          value: 0,
        },
        templateRateMsg: {
          theme: 'green',
          name: 'chart.card.msg-template-rate',
          value: 0,
        },
      },
    };
  },
  watch: {
    filters(newValue) {
      if (newValue !== null) {
        this.loadCards();
      }
    },
  },
  computed: {
    templatesCostExceeded() {
      return this.dataCards.templateRateMsg.value >= 300;
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.loadCards();
    }
  },
  methods: {
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    async loadCards() {
      const data = await this.fetchData('messagesDataCards', MessagesDataCards, this.filters);

      this.dataCards.total.value = data.total;
      this.dataCards.inbound.value = data.inbound;
      this.dataCards.outbound.value = data.outbound;
      this.dataCards.templateMsg.value = data.templateMsg;
      this.dataCards.templateMsgRead.value = data.templateMsgRead || '-';
      this.dataCards.templateRateMsg.value = this.calculateTemplatesRate(data.templateMsg);
    },
    calculateTemplatesRate(totalTemplates) {
      const rate = this.getCostRate(totalTemplates);
      return totalTemplates * rate;
    },
    getCostRate(total) {
      if (total <= 250000) {
        return 0.0266;
      }

      if (total <= 750000) {
        return 0.0262;
      }

      if (total <= 2000000) {
        return 0.0257;
      }

      if (total <= 3000000) {
        return 0.0243;
      }

      if (total <= 4000000) {
        return 0.0229;
      }

      return 0.0215;
    },
    async downloadTemplatesFile() {
      const response = await api.downloadReport('template', this.filters);
      const fileLink = document.createElement('a');

      fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
      fileLink.setAttribute('download', `Templates-${this.filters.startDate}-to-${this.filters.endDate}.xls`);
      fileLink.style.display = 'none';
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    },
    openReports() {
      const query = { ...this.filters, type: 'TemplatesReport' };
      this.$router.push({ name: 'reports', params: query });
    },
  },
};
</script>
