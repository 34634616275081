export default {
  avgAht: {
    theme: 'blue',
    name: 'chart.card.avg-aht',
    value: 0,
  },
  avgAsa: {
    theme: 'green',
    name: 'chart.card.avg-asa',
    value: 0,
  },
  avgAtention: {
    theme: 'orange',
    name: 'chart.card.avg-atention',
    value: 0,
  },
  totalConversations: {
    theme: 'yellow',
    name: 'chart.card.total-conversations',
    value: 0,
  },
  inbound: {
    theme: 'orange',
    name: 'chart.card.total-inbound',
    value: 0,
  },
  outbound: {
    theme: 'red',
    name: 'chart.card.total-outbound',
    value: 0,
  },
  whatsapp: {
    theme: 'green',
    name: 'chart.card.whatsApp-total',
    subtitle: 'chart.card.whatsapp-cost',
    value: 0,
  },
  whatsappPayment: {
    theme: 'blue',
    name: 'chart.card.whatsapp-payments',
    subtitle: 'chart.card.whatsapp-cost',
    value: 0,
    cost: 0,
  },
  whatsappOut: {
    theme: 'red',
    name: 'chart.card.whatsapp-out',
    subtitle: 'chart.card.whatsapp-cost',
    value: 0,
    cost: 0,
  },
  whatsappIn: {
    theme: 'red',
    name: 'chart.card.whatsapp-in',
    subtitle: 'chart.card.whatsapp-cost',
    value: 0,
    cost: 0,
  },
};
