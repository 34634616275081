<template>
  <div>
    <dashboard-messages-cards :filters="filters" />
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-chart
          ref="messagesAgentVsClient"
          name="chart.messages.agent-vs-client"
          chart-id="messagesAgentVsClient"
          type="stackedColumn"
          :translatable="true"
          :chart-data="finalAgentVsClient"
          :doubleChart="doubleChart('messagesAgentVsClient')"
          :stackName="emptyStack('messagesAgentVsClient')"
          :filters="filters"
          @change="chartRangeChanged"
          @change-filter="filterChartData('messagesAgentVsClient', 0, $event)"
          @update-filter="filterChartData('messagesAgentVsClient', 1, $event)"
          @clear-filter="clearChartFilter('messagesAgentVsClient', $event)"
          @clear-date="clearDateFilter('messagesAgentVsClient', $event)"
        />
      </div>
      <div class="cell small-12 medium-12">
        <data-chart
          ref="messagesPerHour"
          name="chart.messages.per-hour"
          chart-id="messagesPerHour"
          type="heatmap"
          :null-to-zeroes="true"
          :chart-data="finalPerHour"
          :doubleChart="doubleChart('messagesPerHour')"
          :stackName="emptyStack('messagesPerHour')"
          :filters="filters"
          :secondHeatmapSeries="secondHeatmapSeries"
          @change="chartRangeChanged"
          @change-filter="filterChartData('messagesPerHour', 0, $event)"
          @update-filter="filterChartData('messagesPerHour', 1, $event)"
          @clear-filter="clearChartFilter('messagesPerHour', $event)"
          @clear-date="clearDateFilter('messagesPerHour', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _uniq from 'lodash/uniq';
import _isEmpty from 'lodash/isEmpty';
import executeQuery from '@/utils/gql-api';
import MessagesAgentVsClient from '@/graphql/queries/messages/agentVsClient.gql';
import MessagesPerHour from '@/graphql/queries/messages/perHour.gql';
import DataChart from '@/components/DataChart.vue';
import DashboardMessagesCards from '@/components/DashboardMessagesCards.vue';

export default {
  components: {
    DataChart,
    DashboardMessagesCards,
  },
  props: {
    filters: Object,
  },
  data() {
    return {
      chart: {
        messagesAgentVsClient: [],
        messagesPerHour: [],
      },
      dateFilter: null,
    };
  },
  watch: {
    filters: {
      handler(newValue) {
        if (newValue !== null) {
          this.initAll();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      campaign: 'setting/getCurrentCampaign',
    }),
    finalAgentVsClient() {
      return this.getChartData('messagesAgentVsClient');
    },
    finalPerHour() {
      return this.chart.messagesPerHour[0];
    },
    secondHeatmapSeries() {
      return this.doubleChart('messagesPerHour')
        ? this.chart.messagesPerHour[1]
        : {};
    },
  },
  methods: {
    initAll() {
      this.resetDateInput();
      this.resetCharts();
      this.fetchChartData('messagesAgentVsClient', 0);
      this.fetchChartData('messagesPerHour', 0);
    },
    async fetchData(id, query, variables = {}) {
      this.$store.commit('dashboard/enableLoader', id);
      const data = await executeQuery(id, query, variables, false);
      this.$store.commit('dashboard/disableLoader', id);

      return data;
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        this.dateFilter = {
          startDate,
          endDate,
        };

        this.resetChart(chartId);
        this.filterChartData(chartId, 0, this.dateFilter);
      }
    },
    async fetchChartData(name, index, paramFilters = {}) {
      const filters = { ...this.filters, ...paramFilters };

      const filterName = paramFilters.name || this.campaign.name;

      const nameToQuery = {
        messagesAgentVsClient: MessagesAgentVsClient,
        messagesPerHour: MessagesPerHour,
      };

      let response;

      if (name === 'messagesPerHour' && !filters.campaignId) {
        response = { series: [] };
      } else {
        response = await this.fetchData(name, nameToQuery[name], filters);
      }

      response.name = filterName;

      response.series = response.series
        .map(serie => ({ ...serie, stack: filterName }));

      const resultChart = [...this.chart[name]];

      resultChart[index] = response;

      this.chart[name] = resultChart;
    },
    getChartData(name) {
      if (this.chart[name].length === 0) {
        return {};
      }

      const final = {
        dateFormat: this.chart[name][0].dateFormat,
        categories: [],
        series: [],
      };

      this.chart[name].forEach((data) => {
        final.categories = [...final.categories, ...data.categories];
      });

      final.categories = _uniq(final.categories);

      const { categories } = final;

      this.chart[name].forEach((data) => {
        const series = data.series.map(serie => ({
          ...serie,
          filter: data.name,
          data: this.fillSeries(
            serie.data,
            data.categories,
            categories,
          ),
        }));
        final.series = [...final.series, ...series];
      });

      return final;
    },
    fillSeries(series, initCategories, finalCategories) {
      return finalCategories.map((final) => {
        const index = initCategories.findIndex(init => init === final);

        return index === -1 ? 0 : series[index];
      });
    },
    async filterChartData(from, index, filters) {
      await this.fetchChartData(from, index, this.getFilters(from, filters));
    },
    clearChartFilter(name, filters) {
      this.chart[name].splice(0, 1);
      this.fetchChartData(name, 0, this.getFilters(name, filters));
    },
    getFilters(name, filters) {
      const chartFilters = { ...filters };

      if (filters.startDate && filters.campaignId) {
        this.$refs[name].resetFilters();
        this.dateFilter = null;
      }

      if (this.dateFilter?.startDate) {
        chartFilters.startDate = this.dateFilter.startDate;
        chartFilters.endDate = this.dateFilter.endDate;
      }

      return chartFilters;
    },
    clearDateFilter(name, filters) {
      this.chart[name].splice(0, 2);
      this.fetchChartData(name, 0,
        {
          campaignId: filters.firstCampaign.id,
          name: filters.firstCampaign.name,
        });
      this.fetchChartData(name, 1,
        {
          campaignId: filters.secondCampaign.id,
          name: filters.secondCampaign.name,
        });
    },
    resetChart(name) {
      this.chart[name].splice(0, 2);
    },
    openReports() {
      const query = { ...this.filters, type: 'TemplatesReport' };
      this.$router.push({ name: 'reports', params: query });
    },
    resetCharts() {
      this.chart.messagesAgentVsClient = [];
      this.chart.messagesPerHour = [];
    },
    resetDateInput() {
      if (!_isEmpty(this.$refs)) {
        this.$refs.messagesAgentVsClient.resetFilters();
        this.$refs.messagesPerHour.resetFilters();
      }
      this.dateFilter = null;
    },
    emptyStack(name) {
      const result = this.chart[name].filter(data => data.series.length === 0);
      return result.length === this.chart[name].length ? '' : result[0]?.name;
    },
    doubleChart(name) {
      return this.chart[name].length === 2;
    },
  },
  mounted() {
    if (this.filters !== null) {
      this.initAll();
    }
  },
};
</script>
